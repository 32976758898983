@keyframes awn-fade-in {
    0% {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@keyframes awn-fade-out {
    0% {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}
@keyframes awn-slide-right {
    0% {
        opacity: 0;
        left: 100%;
    }
    to {
        opacity: 1;
        left: 0;
    }
}
@keyframes awn-slide-left {
    0% {
        opacity: 0;
        right: 100%;
    }
    to {
        opacity: 1;
        right: 0;
    }
}
@keyframes awn-bar {
    0% {
        right: 100%;
    }
    to {
        right: 0;
    }
}
.awn-popup-loading-dots,
.awn-popup-loading-dots:after,
.awn-popup-loading-dots:before {
    border-radius: 50%;
    width: 6px;
    height: 6px;
    animation-fill-mode: both;
    background: #fff;
    animation: awn-loading-dots 1s ease-in-out infinite;
}
.awn-popup-loading-dots {
    position: relative;
    margin-left: 24px;
    display: inline-block;
    color: #fff;
    animation-delay: -0.16s;
}
.awn-popup-loading-dots:after,
.awn-popup-loading-dots:before {
    content: "";
    position: absolute;
    top: 0;
}
.awn-popup-loading-dots:before {
    left: -16px;
    animation-delay: -0.32s;
}
.awn-popup-loading-dots:after {
    left: 16px;
}
@keyframes awn-loading-dots {
    0%,
    80%,
    to {
        box-shadow: 0 0 0 0;
    }
    40% {
        box-shadow: 0 0 0 2px;
    }
}
#awn-popup-wrapper {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.7);
    z-index: 99999;
    opacity: 0;
    animation-name: awn-fade-in;
    animation-timing-function: ease-out;
    animation-fill-mode: both;
}
#awn-popup-wrapper.awn-hiding {
    animation-name: awn-fade-out;
}
#awn-popup-wrapper .awn-popup-body {
    position: relative;
    border-radius: 6px;
    word-break: break-word;
    background: #fff;
    padding: 24px;
    min-width: 320px;
    font-size: 14px;
    max-width: 500px;
}
#awn-popup-wrapper .awn-popup-body.awn-popup-confirm {
    display: flex;
    flex-direction: column;
    align-items: center;
}
#awn-popup-wrapper .awn-popup-body.awn-popup-confirm .fa {
    font-size: 44px;
    color: #c26700;
}
#awn-popup-wrapper .awn-popup-body.awn-popup-async-block {
    background: transparent;
    font-size: 32px;
    font-weight: 700;
    color: #fff;
    text-align: center;
}
#awn-popup-wrapper .awn-popup-title {
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    margin-top: 8px;
}
#awn-popup-wrapper .awn-buttons {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
}
#awn-popup-wrapper .awn-buttons .awn-btn {
    border-radius: 4px;
    border: 0;
    font-weight: 700;
    transition: background 0.2s linear;
    font-size: 14px;
    width: 45%;
    line-height: 32px;
    color: #fff;
}
#awn-popup-wrapper .awn-buttons-1 .awn-btn {
    width: 100%;
}
#awn-popup-wrapper .awn-buttons .awn-btn-success {
    background: #40871d;
}
#awn-popup-wrapper .awn-buttons .awn-btn-success:hover {
    background: #367218;
}
#awn-popup-wrapper .awn-buttons .awn-btn-cancel {
    background: #1c76a6;
}
#awn-popup-wrapper .awn-buttons .awn-btn-cancel:hover {
    background: #186690;
}
#awn-toast-container {
    position: fixed;
    z-index: 99998;
    bottom: 24px;
    right: 24px;
    box-sizing: border-box;
}
#awn-toast-container.awn-top-left,
#awn-toast-container.awn-top-right {
    top: 24px;
    bottom: auto;
}
#awn-toast-container.awn-top-left .awn-toast:first-child,
#awn-toast-container.awn-top-right .awn-toast:first-child {
    margin-top: 16px;
}
#awn-toast-container.awn-bottom-left,
#awn-toast-container.awn-top-left {
    left: 24px;
    right: auto;
}
#awn-toast-container.awn-bottom-left .awn-toast,
#awn-toast-container.awn-top-left .awn-toast {
    right: 100%;
    animation-name: awn-slide-left;
}
#awn-toast-container.awn-bottom-left .awn-toast.awn-hiding,
#awn-toast-container.awn-top-left .awn-toast.awn-hiding {
    right: 0;
}
#awn-toast-container.awn-bottom-right .awn-toast,
#awn-toast-container.awn-top-right .awn-toast {
    left: 100%;
    animation-name: awn-slide-right;
}
#awn-toast-container.awn-bottom-right .awn-toast.awn-hiding,
#awn-toast-container.awn-top-right .awn-toast.awn-hiding {
    left: 0;
}
.awn-toast {
    position: relative;
    cursor: pointer;
    overflow: hidden;
    opacity: 0;
    width: 320px;
    background: #ebebeb;
    margin-top: 16px;
    border-radius: 6px;
    color: grey;
    font-size: 14px;
    animation-timing-function: linear;
    animation-fill-mode: both;
}
.awn-toast-content {
    word-break: break-word;
}
.awn-toast-label {
    display: block;
    text-transform: uppercase;
    color: grey;
    font-size: 18px;
}
.awn-toast-icon {
    position: absolute;
    right: 16px;
    top: 6px;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.awn-toast-icon .fa {
    font-size: 44px;
    color: grey;
}
.awn-toast-wrapper {
    padding: 22px 88px 16px 16px;
    border: 2px solid #d1d1d1;
    border-radius: 6px;
}
.awn-toast-progress-bar {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 6px;
}
.awn-toast-progress-bar:after {
    content: " ";
    background: grey;
    position: absolute;
    width: 100%;
    right: 100%;
    top: 0;
    height: 6px;
    animation-name: awn-bar;
    animation-duration: inherit;
    animation-timing-function: linear;
    animation-fill-mode: both;
}
.awn-toast.awn-toast-progress-bar-paused .awn-toast-progress-bar:after {
    animation-play-state: paused;
}
.awn-toast.awn-hiding {
    animation-name: awn-fade-out !important;
}
.awn-toast.awn-toast-success {
    background: #dff8d3;
    color: #40871d;
}
.awn-toast.awn-toast-success .awn-toast-wrapper {
    border-color: #a7d590;
}
.awn-toast.awn-toast-success .fa,
.awn-toast.awn-toast-success b {
    color: #40871d;
}
.awn-toast.awn-toast-success .awn-toast-progress-bar:after {
    background: #40871d;
}
.awn-toast.awn-toast-info {
    background: #d3ebf8;
    color: #1c76a6;
}
.awn-toast.awn-toast-info .awn-toast-wrapper {
    border-color: #9fd3ef;
}
.awn-toast.awn-toast-info .fa,
.awn-toast.awn-toast-info b {
    color: #1c76a6;
}
.awn-toast.awn-toast-info .awn-toast-progress-bar:after {
    background: #1c76a6;
}
.awn-toast.awn-toast-alert {
    background: #f8d5d3;
    color: #a92019;
}
.awn-toast.awn-toast-alert .awn-toast-wrapper {
    border-color: #f0a29d;
}
.awn-toast.awn-toast-alert .fa,
.awn-toast.awn-toast-alert b {
    color: #a92019;
}
.awn-toast.awn-toast-alert .awn-toast-progress-bar:after {
    background: #a92019;
}
.awn-toast.awn-toast-warning {
    background: #ffe7cc;
    color: #c26700;
}
.awn-toast.awn-toast-warning .awn-toast-wrapper {
    border-color: #ffc480;
}
.awn-toast.awn-toast-warning .fa,
.awn-toast.awn-toast-warning b {
    color: #c26700;
}
.awn-toast.awn-toast-warning .awn-toast-progress-bar:after {
    background: #c26700;
}
[class^="awn-"] {
    box-sizing: border-box;
}


.bm-burger-bars {
    background: #8cbf41 !important;
  }