body {
    overflow-x: hidden;
}

/* width */

::-webkit-scrollbar {
    width: 2px;
}

/* Track */

::-webkit-scrollbar-track {
    background: #2b2b2b;
}

/* Handle */

::-webkit-scrollbar-thumb {
    background-color: rgb(182, 182, 182);
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
    background: rgb(230, 230, 230);
    width: 10px;
}

.switch-wrapper {
    position: relative;
}

/* input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
} */

.switch-wrapper > div {
    position: absolute;
    width: 100%;
    background-image: url("./Medias/background.png");
    background-size: cover;
    min-height: 100vh;
}

.lazy-load-image-background {
    height: 100%;
}

.menuImageSmall {
    width: 100%;
}

.menuImageMedium {
    width: 49%;
}

.menuImageBig {
    width: 45%;
}

.dropdown-toggle:focus,
.dropdown:focus,
.btn:focus,
.btn-Primary:focus,
.noFocus:focus {
    outline: none !important;
    border: none !important;
    box-shadow: 0 0 0 0 rgb(0 123 255 / 0%) !important;
}

.hoverScale {
    transition: transform 0.2s;
}

.hoverScale:hover,
.hoverScale:focus {
    transition: transform 0.2s;
    transform: scale(1.1);
}

.hoverScaleImage {
    transition: transform 0.2s;
}

.hoverScaleImage:hover,
.hoverScaleImage:focus {
    transition: transform 0.2s;
    transform: scale(1.03);
}

/*css sandrine*/

@font-face {
    font-family: "myFont";
    src: local("myFont"), url("./fonts/myFont.ttf") format("opentype");
}

body {
    /* background: #11998e; fallback for old browsers */
    /* background: url("./media/backgropund.png"); W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    min-height: 100vh;
    background-size: cover;
    overflow-x: hidden;
    font-family: myFont;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.hoverScale {
    transition: transform 0.2s;
}

.hoverScale:hover,
.hoverScale:focus {
    transition: transform 0.2s;
    transform: scale(1.05);
}

::-webkit-scrollbar {
    width: 2px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #2b2b2b;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background-color: rgb(182, 182, 182);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: rgb(230, 230, 230);
    width: 10px;
}

.switch-wrapper {
    position: relative;
}

.switch-wrapper > div {
    position: absolute;
    width: 100%;
}

@keyframes expandCircle {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.1);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes contractCircle {
    0% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}

.circle-scale-up {
    animation: expandCircle 1s forwards;
    /* animation-timing-function: cubic-bezier(0.79, -0.08, 0.37, 1.47); */
    animation-iteration-count: infinite;
}

.circle-scale-down {
    animation: contractCircle 0.2s forwards;
    animation-timing-function: cubic-bezier(0.79, -0.08, 0.37, 1.47);
}

*:focus {
    outline: none;
}

/* Position and sizing of burger button */
.bm-burger-button {
    position: fixed;
    width: 26px;
    height: 20px;
    left: 10px;
    top: 10px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
    background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
    background: #0877c2;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
    height: 24px;
    width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
    background: #bdc3c7;
}

/*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
.bm-menu-wrap {
    position: fixed;
    height: 100%;
}

/* General sidebar styles */
.bm-menu {
    background: #373a47;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
    fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
}

/* Individual item */
.bm-item {
    display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
    background: rgba(0, 0, 0, 0.3);
}

.img-wrap {
    position: relative;
}
.img-wrap::before {
    background-image: linear-gradient(to left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 1;
    content: "";
}

.img-wrap-medium {
    position: relative;
}
.img-wrap-medium::before {
    background-image: linear-gradient(to top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 1;
    content: "";
}

.rdt_Table {
    overflow: hidden;
    border-radius: 0px;
}
